import React from "react";
import {Trans, useTranslation, I18nextContext, Link} from 'gatsby-plugin-react-i18next';
import Layout from "../../../components/layout";
import Seo from "../../../components/seo";
import {graphql} from 'gatsby';
import {StaticImage} from "gatsby-plugin-image";
import {returnLocaleURL} from "../../../utils/utils";
import Solution from "../../../components/solutions/Solution";
import pedestals from './images/applications/pedestals.jpg';
import personalStorage from './images/applications/personal-storage.jpg';
import credenzas from './images/applications/credenzas.jpg';
import towers from './images/applications/towers.jpg';
import CustomerVideo from "../../../components/solutions/CustomerVideo";
import posterSolution from "./images/poster-workspace.jpg";
import ElectronicLockLineup from "../../../components/solutions/ElectronicLockLineup";
import GatsbySlideshow from "../../../components/carousel/gatsby-slideshow/GatsbySlideshow";

const IndexPage = (data) => {

  const {t} = useTranslation();
  const context = React.useContext(I18nextContext);
  let sl = context.language;
  const d = data.data.Slides.edges;
  const images = [];
  for(let i= 0; i<= d.length-1; i++) {
    images.push(d[i].node.childImageSharp);
  }
  const projectAsides = [
    '<span>united states</span><br>' +
    '<h2>Big Tech</h2>' +
    '<p><a href="'+returnLocaleURL(sl, '/products/')+'">Digilock Aspire RFID</a></p>',

    '<span>the netherlands</span><br>' +
    '<h2>Nike</h2>' +
    '<p><a href="'+returnLocaleURL(sl, '/products/')+'">Digilock Sola Keypad</a></p>',

    '<span>italy</span><br>' +
    '<h2>Amazon</h2>' +
    '<p><a href="'+returnLocaleURL(sl, '/products/')+'">Digilock Versa RFID</a></p>',

    '<span>united states</span><br>' +
    '<h2>Genentech</h2>' +
    '<p><a href="'+returnLocaleURL(sl, '/products/')+'">Digilock Versa Mini</a></p>',

    '<span>united states</span><br>' +
    '<h2>Accenture</h2>' +
    '<p><a href="'+returnLocaleURL(sl, '/products/')+'">Digilock 4G Keypad</a></p>',

    '<span>the netherlands</span><br>' +
    '<h2>Jones Lang Lasalle</h2>' +
    '<p><a href="'+returnLocaleURL(sl, '/products/')+'">Digilock 4G Keypad</a></p>',

    '<span>united states</span><br>' +
    '<h2>Accenture</h2>' +
    '<p><a href="'+returnLocaleURL(sl, '/products/')+'">Digilock 4G Keypad</a></p>',

    '<span>united states</span><br>' +
    '<h2>IBM</h2>' +
    '<p><a href="'+returnLocaleURL(sl, '/products/')+'">Digilock 4G RFID</a></p>',

    '<span>spain</span><br>' +
    '<h2>Amazon</h2>' +
    '<p><a href="'+returnLocaleURL(sl, '/products/')+'">Digilock Versa RFID</a></p>',

    '<span>united arab emirates</span><br>' +
    '<h2>Mastercard</h2>' +
    '<p><a href="'+returnLocaleURL(sl, '/products/')+'">Digilock Aspire RFID</a></p>',

    '<span>united states</span><br>' +
    '<h2>Amgen</h2>' +
    '<p><a href="'+returnLocaleURL(sl, '/products/')+'">Digilock Versa Keypad</a></p>',

    '<span>the Netherlands</span><br>' +
    '<h2>Astellas Pharma</h2>' +
    '<p><a href="'+returnLocaleURL(sl, '/products/')+'">Digilock 4G RFID</a></p>'
  ];
  const solutions = [
    {
      img: personalStorage,
      label: 'Drawer Locks',
      alt: 'personal_storage',
      bg: 'light'
    },
    {
      img: pedestals,
      label: 'Pedestal Locks',
      alt: 'pedestals',
      bg: 'dark'
    },
    {
      img: credenzas,
      label: 'File Cabinet Locks',
      alt: 'file cabinet locks',
      bg: 'light'
    },
    {
      img: towers,
      label: 'Cabinet Locks',
      alt: 'cabinet locks',
      bg: 'light'
    }

  ];

  return (
      <Layout>
        <Seo
            title={t('solutions_workspace')}
            description={t('des_solutions_workspace')}
        />
        <div className="solutions workspace">
          <section className="hero --hero-image no-pad">
            <StaticImage
                src={'../../../images/industries/hero-workspace.jpg'}
                quality={100}
                layout={'fullWidth'}
                formats={["auto", "webp", "avif"]}
                alt="workspace"
            />
            <h1>
              <Trans>
                workspace
              </Trans>
              <span>
                <Trans>workspace_hero_subhead</Trans>
              </span>
            </h1>
          </section>
          <section className="customer-video healthcare">
            <div className="container">
              <div className={'grid-container'}>
                <h2><Trans>workspace_subhero_title</Trans></h2>
                <ul>
                  <li><Trans>workspace_subhero_list_l1</Trans></li>
                  <li><Trans>workspace_subhero_list_l2</Trans></li>
                  <li><Trans>workspace_subhero_list_l3</Trans></li>
                  <li><Trans>workspace_subhero_list_l4</Trans></li>
                  <li><Trans>workspace_subhero_list_l5</Trans></li>
                  <li><Trans>workspace_subhero_list_l6</Trans></li>
                </ul>
              </div>
              <div className="video-holder">
                <StaticImage
                    src="./images/workspace-sub-hero.jpg"
                    quality={100}
                    width={508}
                    height={312}
                    formats={["auto", "webp", "avif"]}
                    loading={'lazy'}
                    alt="workspace office"
                />
              </div>
            </div>
          </section>
          <ElectronicLockLineup showPivot={true} showCurve={true} showAspire={true} showVersa={true} showOrbit={true} showCTA={true} label={'workspace_lineup_label'}   />
          <section className="industry-carousel">
            <div className="container">
              <h2><Trans>workspace_carousel</Trans></h2>
              <GatsbySlideshow images={images} overlays={projectAsides} />
            </div>
          </section>
          <br/>
          <section className="customer-video healthcare">
              <CustomerVideo
                  poster={posterSolution}
                  title={'workspace_customer_video_title'}
                  copy={'workspace_customer_video_copy'}
                  vidURL={'https://cdn.mediavalet.com/usva/digilock/-lFm5r1GM060geeLH4_GPQ/Hym5jRhzUUqpuXc3d6YSIg/Widescreen720p/workspace.mp4'}
              />
          </section>

          <section className="industry-uses">
            <div className="container">
              <h2><Trans>workspace_popular_uses</Trans></h2>
              <Solution items={solutions}/>
            </div>
          </section>
        </div>
      </Layout>
  )
};

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    Slides: allFile(
    filter: {relativeDirectory: {eq: "solutions/workspace"}}
    sort: {fields: [name], order: ASC}
    ) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
      }
    }
  }
  
  }
`;